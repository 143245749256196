import * as React from "react"
import {Link} from 'gatsby'
import favicon from '../images/favicon.svg'
import '../css/main.css'

const IndexPage = ({
  data: {
    allMarkdownRemark: { edges:allPosts },
  },
}) => {
  return (
    <main className="page">
      <title>Home Page</title>
      <h1>
        Hi – I’m  Caudurodev{" "}, a Senior Fullstack Developer based in Berlin. <img alt="caudurodev Logo" src={favicon} />
        <br />
        <span className="headingAccent">
          — If you'd like, you can connect with me on {" "}
          <a href="https://www.linkedin.com/in/rodrigocauduro/">Linkedin</a> or{" "}
          <a href="https://github.com/caudurodev">Github</a>.
        </span>
      </h1>
      <p>
        My preferred technologies are <code>React, Vue, Python, Node and PHP</code>. 
        {" "}
        <br />
        I also have some preferences in devops and they are normally a mix of{" "}
        <code>Docker, Kubernettes, Gitlab (CI/CD) and Digital Ocean</code>.{" "}
        <span role="img" aria-label="Sunglasses smiley emoji">
          😎
        </span>
      </p>
      <h2>Below are some of my posts on tech-related subjects:</h2>
      <ul>
        {allPosts.map(node => (
          <li key={node.node.id}>
            <span>
              <Link className="post-title" to={`/posts${node.node.fields.slug}`}>
                {node.node.frontmatter.title} 
              </Link>
              <p className="description">{node.node.excerpt}</p>
            </span>
          </li>
        ))}
      </ul>
      <p>This site is built with React / Gatsby and deployed statically to Netlify.</p>
    </main>
  )
}

export const pageQuery = graphql`
  query BlogPostIndex {
  allMarkdownRemark(sort: {order: DESC, fields: [frontmatter___date]}, limit: 1000) {
    edges {
      node {
        id
        html
        fields{
          slug
        }
        excerpt(pruneLength: 140)
        frontmatter {
          date
          title
          coverImage
        }
      }
    }
  }
}
`

export default IndexPage
